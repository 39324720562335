import * as React from "react"
import { PageProps, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import { RiPlantFill as PlantIcon } from "react-icons/ri"
import {
  Container,
  Text,
  Divider,
  Flex,
  Tag,
  TagLabel,
  Grid,
  Box,
  Link as ExternalLink,
  Icon,
  Stack,
} from "@chakra-ui/react"
import { Layout } from "../components/blocks/layout"
import { Link } from "../components/link"
import { SkipNavContent } from "../components/a11y/skip-nav"
import { Prose } from "../components/typography/prose"
import { SEO } from "../components/seo"
import { Heading } from "../components/typography/heading"
import { Spacer } from "../components/blocks/spacer"
import { components } from "../components/mdx"
import { article } from "../constants/json-ld"
import { ShareAnywhereButton, TwitterButton } from "../components/buttons"
import { site } from "../constants/meta"

type DataProps = {
  wiki: {
    title: string
    slug: string
    body: string
    icon: string
    seoLastUpdated: string
    lastUpdated: string
    seoDate: string
    yearDate: string
    date: string
    tags: string[]
    timeToRead: number
    excerpt: string
    parent: {
      parent: {
        relativePath: string
      }
    }
  }
}

const WikiTemplate: React.FC<PageProps<DataProps>> = ({ data: { wiki }, location: { pathname } }) => {
  const [hasShareApi, setHasShareApi] = React.useState(false)

  React.useEffect(() => {
    setHasShareApi(!!window.navigator.share)
  }, [])
  return (
    <Layout>
      <SEO title={wiki.title} description={wiki.excerpt} image="/social/digital-wiki.jpg">
        <meta name="twitter:label1" value="Time To Read" />
        <meta name="twitter:data1" value={`${wiki.timeToRead} Minutes`} />
        <meta name="twitter:label2" value="Category" />
        <meta name="twitter:data2" value={wiki.icon} />
        <meta name="article:published_time" content={wiki.seoDate} />
        <meta name="article:modified_time" content={wiki.seoLastUpdated} />
        <script type="application/ld+json">
          {JSON.stringify(
            article({
              isWiki: true,
              post: {
                title: wiki.title,
                description: wiki.excerpt,
                slug: wiki.slug,
                image: `/social/digital-wiki.jpg`,
                date: wiki.seoDate,
                lastUpdated: wiki.seoLastUpdated,
                year: wiki.yearDate,
              },
              category: {
                name: `Wiki`,
                slug: `/wiki`,
              },
            })
          )}
        </script>
      </SEO>
      <Container variant="proseRoot">
        <SkipNavContent>
          <Heading as="h1">{wiki.title}</Heading>
          <Spacer size={6} axis="vertical" />
          <Divider />
          <Spacer size={4} axis="vertical" />
          <Grid gridGap={2} gridTemplateColumns={[`1fr`, null, `1fr auto`]} fontSize={[`sm`, `md`, null, null, `lg`]}>
            <Text>
              Created {wiki.date} – Last Updated {wiki.lastUpdated}
            </Text>
            <Flex flexWrap="wrap" justifyContent={[`flex-start`, null, `flex-end`]}>
              {wiki.tags.map((tag) => (
                <Box as="span" ml={2} _first={{ ml: 0 }} key={tag}>
                  {tag}
                </Box>
              ))}
            </Flex>
            <Tag colorScheme="green" justifySelf="flex-start">
              <TagLabel>
                <Link to="/wiki">Wiki</Link>
              </TagLabel>
            </Tag>
          </Grid>
          <Spacer size={12} axis="vertical" />
          <Prose>
            <MDXProvider components={components}>
              <MDXRenderer>{wiki.body}</MDXRenderer>
            </MDXProvider>
          </Prose>
          <Spacer size={12} axis="vertical" />
          <Divider />
          <Spacer size={6} axis="vertical" />
          <Stack
            direction={[`column`, `row`]}
            display="flex"
            spacing="5"
            justifyContent={[`flex-start`, `space-between`]}
            alignItems={[`flex-start`, `center`]}
          >
            {hasShareApi ? (
              <Stack direction={[`column`, `row`]}>
                <ShareAnywhereButton link={`${site.url}${wiki.slug}`} message={wiki.title} />
                <TwitterButton link={`${site.url}${wiki.slug}`} message={wiki.title} variant="outline" />
              </Stack>
            ) : (
              <TwitterButton link={`${site.url}${wiki.slug}`} message={wiki.title} />
            )}
          </Stack>
          <Spacer size={12} axis="vertical" />
        </SkipNavContent>
      </Container>
    </Layout>
  )
}

export default WikiTemplate

export const query = graphql`
  query WikiTemplate($slug: String!) {
    wiki(slug: { eq: $slug }) {
      title
      body
      slug
      icon
      seoLastUpdated: lastUpdated
      lastUpdated(formatString: "MMM DD, YYYY")
      seoDate: date
      date(formatString: "MMM DD, YYYY")
      yearDate: date(formatString: "YYYY")
      tags
      timeToRead
      excerpt
      ... on MdxWiki {
        parent {
          ... on Mdx {
            parent {
              ... on File {
                relativePath
              }
            }
          }
        }
      }
    }
  }
`
